
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































































































































































































































@import '~vue-file-agent/dist/vue-file-agent.css';

.contact-project {
  border-top: 1px solid $c-gray-light;
}

.contact-project__title {
  @extend %text-center;

  margin-top: $spacing * 2;

  @include mq(m) {
    margin-top: $spacing * 3;
  }

  @include mq(xl) {
    margin-top: $spacing * 4.5;
  }
}

.form-inner {
  @include mq(m) {
    padding: 0 col(2, 12);
  }

  @include mq(xl) {
    padding: 0 col(3, 12);
  }
}

.form__fieldset {
  margin-left: 0;
  padding: 0;
  border: 0;

  .select,
  .textarea {
    @include mq('s') {
      grid-column: 1 / 3;
    }
  }
}

.form__fieldset,
.form__confirmation {
  @include fluid(
    column-gap,
    (
      xxs: 10px,
      xxl: 35px,
    )
  );
  @include fluid(
    row-gap,
    (
      xxs: 20px,
      xxl: 25px,
    )
  );

  margin-top: 0;

  @include mq('s') {
    display: grid;
    grid-template: auto / 1fr 1fr;
  }
}

.form__files {
  grid-column: 1/3;
}

.form-label {
  @extend %ff-alt;

  margin-bottom: 1rem;
  color: $c-gray-dark;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @include mq(m) {
    font-size: 1.3rem;
  }
}

.form__input {
  position: relative;
  margin-top: $spacing;

  &.full {
    grid-column: 1 / 3;
  }
}

.form__input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq(s) {
    justify-content: flex-start;
  }
}

.form-tooltip-inner {
  @include center-xy;

  position: absolute;
  top: 0;
  right: 0;
}

.form__upload {
  width: 100%;
  margin-top: 1rem;
  color: $c-gray;
}

.form__confirmation {
  margin-left: 0;
  padding: 0;
  border: 0;

  ::v-deep .checkbox .form-feedback {
    position: static;
  }

  @include mq('s') {
    display: block;
  }

  @include mq('xl') {
    grid-template: auto / 1fr 300px;
    row-gap: 0;
  }
}

.form__confirmation__recaptcha {
  @include mq('xl') {
    grid-row: 2 / 2;
    grid-column: 1 / 2;
    margin-top: $spacing;
  }
}

.form__send {
  position: relative;
  padding: $spacing * 2 0;
  text-align: center;
  border: 0;

  /* stylelint-disable-next-line no-descending-specificity */
  .form-feedback {
    position: absolute;
    top: 0;
    text-align: center;
  }

  @include mq(m) {
    margin-top: $spacing * 3;
    text-align: right;

    .form-feedback {
      @include center-y;

      text-align: left;
    }
  }
}

.form__feedback {
  width: 100%;
}

.form-label__required {
  color: $c-mustard;
}
